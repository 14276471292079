#prev-card{
    margin: 1%;

}

#row{
    height: 100%;
    width: 100%;

}

#col{
    height: 100%;
    padding: 5%;
}
