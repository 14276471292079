#all-frame{
        background-color: #b7b7b7;
        overflow: auto;

        /*display: flex;*/
        flex-direction: column-reverse;
        min-height: 70vh;
        max-height: 70vh;
        /*width: 55vw;*/
        background-image: url("../assets/6876287.jpg");

        box-shadow: 4px 3px 2px 2px rgba(0, 0, 0, 0.1);


}



#root > div > div > div > div > div.col > div > div:nth-child(2) > div > div
{
        top: -120px;
        min-width:0;
}




