.App {

    --white: #F6F7EB;
    --black: #393E41;
    --brad: 5px;


    text-align: center;
    padding: 3%;
    background-color: var(--black);
    background-image: url("./assets/geometric-leaves.png");
    /* color: var(--white); */
    overflow-x: hidden;

    /* background: linear-gradient(360deg, #e8dfd5 20%, #ECE5DD 80%, #128C7E 80%, #d1e8e2 80%); */
    /* Set up proportionate scaling */
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
}

.emoji-container {
    height: 40vh;
    overflow-y: scroll;
    background-color: var(--white);
    width: 50%;
    margin: 0 auto;
    border-radius: var(--brad);
    margin-top: 5vh;
    padding: 25px;
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    box-shadow: 4px 3px 2px 2px rgba(0, 0, 0, 0.1);
    max-height: 100%;
    max-width: 100%;
    /*background-color: #ACCBCA;*/
    height: 100%;
    background: linear-gradient(360deg, #8ac0be, #ACCBCA);


    /*min-height: 100vh;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*font-size: calc(10px + 2vmin);*/
    /*color: white;*/
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


