#card-style {
    padding: 3%;
    width: 40%;
    height: 100%;
    margin: auto;
    position: relative;
    min-height: 70vh;
    max-height: 70vh;
    overflow: auto;
    box-shadow: 4px 3px 2px 2px rgba(0, 0, 0, 0.1);
}

#container-style {
    width: 70%;
}