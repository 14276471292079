#special-alert{
    margin-top: 20px;
    position: relative;
    width: 50%;
    height: 30%;
}

#card-style-sign-in{
     padding: 3%;
    width: 40%;
    height: 100%;
    margin: auto;
    position: relative;
    box-shadow: 4px 3px 2px 2px rgba(0, 0, 0, 0.1);
}
